import React from "react";
import { navigate } from "gatsby";

import Layout from "../../components/layout";
import { BACKEND_BASE_URL } from "../../constant/constants";
import { Seo } from "../../components";

import { MissionBSection, Dropdown, NewsEventSlider } from "../../components";

const MissionFieldInfo = ({
  selected_mission_field,
  mission_field_info,
  mission_fields_index,
}) => {
  const onChange = e => {
    navigate("/mission-fields/" + e.target.value);
  };

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Dropdown
              data={mission_fields_index}
              selected={selected_mission_field}
              onChange={onChange}
            />
            <>
              <MissionBSection
                data={mission_field_info}
                title={mission_field_info.markdown_title}
              />
              <NewsEventSlider />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const MissionFieldBySlug = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.mission_field_info.name}
        description={serverData.mission_field_info.markdown}
        image_url={serverData.mission_field_info.image_url}
      />
      <MissionFieldInfo
        selected_mission_field={serverData.mission_field_info.slug}
        mission_field_info={serverData.mission_field_info}
        mission_fields_index={serverData.mission_fields_index}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const mission_field_info_res = await fetch(
      BACKEND_BASE_URL + "mission-fields/" + context.params.slug
    );
    const mission_fields_index_res = await fetch(
      BACKEND_BASE_URL + "mission-fields"
    );
    if (!mission_field_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!mission_fields_index_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        mission_field_info: await mission_field_info_res.json(),
        mission_fields_index: await mission_fields_index_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default MissionFieldBySlug;
